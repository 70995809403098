"use strict"

const KTKeeniconsModal = function () {
    let keeniconsListing
    let keeniconsToggler
    let selectedIconName
    let selectedIconCode
    let selectedIconHtml
    let callback

    const keeniconsStyleToggle = function () {
        const form = keeniconsListing.querySelector('form')
        if (form) form.addEventListener('submit', function (e) {
            e.preventDefault()
        })

        const toggleButtons = [].slice.call(keeniconsToggler.querySelectorAll('[data-kt-keenicons-style-toggle="true"]'))

        function escapeHtml(text) {
            if (text == null) return ''

            return String(text).
                replace(/&/g, '&amp').
                replace(/</g, '&lt').
                replace(/>/g, '&gt').
                replace(/"/g, '&quot').
                replace(/'/g, '&#039')
        }

        KTUtil.on(document.body, `#${keeniconsToggler.id} [data-kt-keenicons-style-toggle="true"]:checked`, 'click', function (e) {
            const style = this.value

            keeniconsListing.classList.remove('keenicons-solid')
            keeniconsListing.classList.remove('keenicons-duotone')
            keeniconsListing.classList.remove('keenicons-outline')
            keeniconsListing.classList.add('keenicons-' + style)
        })

        KTUtil.on(document.body, `#${keeniconsListing.id} [data-kt-icon-use="true"]`, 'click', function (e) {
            const style = document.querySelector(`#${keeniconsToggler.id} [data-kt-keenicons-style-toggle="true"]:checked`).value

            const previewEl = this.closest(`#${keeniconsListing.id} [data-kt-icon-preview="true"]`)
            const iconNameEl = previewEl.querySelector(`#${keeniconsListing.id} [data-kt-icon-name="true"]`)

            selectedIconName = iconNameEl.innerHTML
            selectedIconName = selectedIconName.trim()
            if (style === 'duotone') {
                const paths = parseInt(this.getAttribute('data-kt-icon-paths'))
                selectedIconHtml = '<i class="ki-duotone ki-' + selectedIconName + '">'
                selectedIconCode = escapeHtml('<i class="ki-duotone ki-' + selectedIconName + '">')
                for (let i = 1; i <= paths; i++) {
                    selectedIconHtml += '\n    <i class="path' + i + '"></i>'
                    selectedIconCode += escapeHtml('\n <i class="path' + i + '"></i>')
                }
                selectedIconHtml += '\n</i>'
                selectedIconCode += escapeHtml('\n</i>')
            } else {
                selectedIconHtml = '<i class="ki-' + style + ' ki-' + selectedIconName + '"></i>'
                selectedIconCode = escapeHtml('<i class="ki-' + style + ' ki-' + selectedIconName + '"></i>')
            }
            callback(selectedIconHtml)
            $('#modal_keenicons_icons').modal('hide')
        })
    }

    return {
        init: function (cb = (response) => { }) {
            keeniconsListing = document.querySelector('#modal_keenicons_listing')
            keeniconsToggler = document.querySelector('#modal_keenicons_toggler')
            callback = cb
            if (keeniconsListing) {
                keeniconsStyleToggle()
            }
        }
    }
}

// On document ready
// KTUtil.onDOMContentLoaded(function () {
//     KTKeeniconsModal.init()
// })