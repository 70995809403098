"use strict"

const KTKeenicons = function () {
    let keeniconsListing
    let keeniconsToggler
    let keeniconsModal
    let selectedIconName
    let selectedIconCode
    let selectedIconHtml

    const keeniconsStyleToggle = function () {
        const form = keeniconsListing.querySelector('form')
        if (form) form.addEventListener('submit', function (e) {
            e.preventDefault()
        })

        const toggleButtons = [].slice.call(keeniconsToggler.querySelectorAll('[data-kt-keenicons-style-toggle="true"]'))

        function escapeHtml(text) {
            if (text == null) return ''

            return String(text).
                replace(/&/g, '&amp').
                replace(/</g, '&lt').
                replace(/>/g, '&gt').
                replace(/"/g, '&quot').
                replace(/'/g, '&#039')
        }

        KTUtil.on(document.body, `#${keeniconsToggler.id} [data-kt-keenicons-style-toggle="true"]:checked`, 'click', function (e) {
            const style = this.value

            keeniconsListing.classList.remove('keenicons-solid')
            keeniconsListing.classList.remove('keenicons-duotone')
            keeniconsListing.classList.remove('keenicons-outline')
            keeniconsListing.classList.add('keenicons-' + style)
        })

        KTUtil.on(document.body, `#${keeniconsListing.id} [data-kt-icon-use="true"]`, 'click', function (e) {
            const style = document.querySelector(`#${keeniconsToggler.id} [data-kt-keenicons-style-toggle="true"]:checked`).value

            const previewEl = this.closest(`#${keeniconsListing.id} [data-kt-icon-preview="true"]`)
            const iconNameEl = previewEl.querySelector(`#${keeniconsListing.id} [data-kt-icon-name="true"]`)

            selectedIconName = iconNameEl.innerHTML
            selectedIconName = selectedIconName.trim()
            if (style === 'duotone') {
                const paths = parseInt(this.getAttribute('data-kt-icon-paths'))
                selectedIconHtml = '<i class="ki-duotone ki-' + selectedIconName + '">'
                selectedIconCode = escapeHtml('<i class="ki-duotone ki-' + selectedIconName + '">')
                for (let i = 1; i <= paths; i++) {
                    selectedIconHtml += '\n    <i class="path' + i + '"></i>'
                    selectedIconCode += escapeHtml('\n <i class="path' + i + '"></i>')
                }
                selectedIconHtml += '\n</i>'
                selectedIconCode += escapeHtml('\n</i>')
            } else {
                selectedIconHtml = '<i class="ki-' + style + ' ki-' + selectedIconName + '"></i>'
                selectedIconCode = escapeHtml('<i class="ki-' + style + ' ki-' + selectedIconName + '"></i>')
            }

            const myModalEl = new bootstrap.Modal(keeniconsModal)
            myModalEl.show()
        })
        
        keeniconsModal.addEventListener('show.bs.modal', event => {
            // console.log(selectedIconName)
            // console.log(selectedIconHtml)
            keeniconsModal.querySelector('#kt_keenicons_use_title').innerHTML = selectedIconName
            keeniconsModal.querySelector('#kt_keenicons_use_code').innerHTML = selectedIconCode
        })

        // Copy
    }

    const keeniconsHandleCodeCopy = function () {
        // Select elements
        const target = document.getElementById('kt_keenicons_use_code')
        const button = document.getElementById('kt_keenicons_use_code_copy')

        // Init clipboard -- for more info, please read the offical documentation: https://clipboardjs.com/
        const clipboard = new ClipboardJS(button, {
            target: target,
            text: function (target) {
                return target.innerText
            }
        })

        // Success action handler
        clipboard.on('success', function (e) {
            button.classList.add('active')
            button.querySelector('.ki-copy').classList.add('d-none')
            button.querySelector('.ki-check').classList.remove('d-none')

            setTimeout(function () {
                button.classList.remove('active')
                button.querySelector('.ki-copy').classList.remove('d-none')
                button.querySelector('.ki-check').classList.add('d-none')
            }, 3000)
        })
    }

    return {
        init: function (listing = '#keenicons_listing', toggler = '#keenicons_toggler', modal = 'kt_keenicons_use') {
            keeniconsListing = document.querySelector(listing)
            keeniconsToggler = document.querySelector(toggler)
            keeniconsModal = document.getElementById(modal)
            if (keeniconsListing) {
                keeniconsStyleToggle()
                keeniconsHandleCodeCopy()
            }
        }
    }
}

// On document ready
// KTUtil.onDOMContentLoaded(function () {
//     KTKeenicons.init()
// })