"use strict"

const MetronicThemeMode = function () {

    const init = () => {
        // : fix src/js/layout/theme-mode.js
        // : var setMode = function(mode, menuMode)

        // * HIGH-CONTRAST
        document.querySelector('[data-high-contrast="true"]').addEventListener('click', (el) => {
            KTCookie.set("high-contrast", el.target.checked ? "on" : "off")
            zApp.reloadPage()
        })
        
        // * COLOR
        document.querySelectorAll('[data-theme-color-btn]').forEach(btn => btn.addEventListener('click', () => {
            document.querySelector('html').dataset.themeColor = btn.dataset.themeColorBtn
            localStorage.setItem("theme-color", btn.dataset.themeColorBtn)
        }))

        // * MODE
        KTThemeMode.on("kt.thememode.change", function () {
            var menuMode = KTThemeMode.getMenuMode()
            var mode = KTThemeMode.getMode()
            KTCookie.set("mode", mode)
            KTCookie.set("menuMode", menuMode)
        })
        
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', async event => {
            let themeMode = KTThemeMode.getMenuMode()
            let mode = event.matches ? 'dark' : 'light'
            if (themeMode !== 'system') return
            KTThemeMode.setMode(mode, 'system')
            KTCookie.set("mode", mode)
            
            const message = event.matches ?
                doLANG("El navegador a cambiado a modo oscuro") :
                doLANG("El navegador a cambiado a modo claro")

            if (message) await Swal.fire({
                text: message || '',
                // icon: "info",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                    confirmButton: "btn btn-light-primary"
                }
            })
        })
    }

    return {
        init: function () {
            if (document.querySelector('[data-kt-element="theme-mode-menu"]')) init()
        }
    }
}

KTUtil.onDOMContentLoaded(function () {
    MetronicThemeMode().init()
})