"user strict";

const MetronicLandingHeader = function () {
    const header = document.querySelector('.landing-header')

    const init = function () {
        initHeader()
    }

    initHeader = () => {
        headerPrimary = header.querySelector('.header-primary')
        headerSecondary = header.querySelector('.header-secondary')
        
        headerSecondary.querySelectorAll('a.menu-link:not(.skip-highlight):not(.force-highlight)').forEach(el => el.classList.remove('active'))
        headerSecondary.querySelectorAll('[role="tabpanel"]').forEach(el => el.classList.remove('active', 'show'))
        headerSecondary.querySelectorAll('.menu-accordion').forEach(el => el.classList.remove('hover', 'show'))
        
        headerSecondary.querySelectorAll('a.menu-link.force-highlight').forEach(el => el.classList.add('active'))
        headerSecondary.querySelectorAll('a.menu-link:not(.skip-highlight)').forEach((el) => {
            if (el.href.substring(el.href.lastIndexOf(_.DIR)) === window.location.href) {
                el.classList.add('active')
                el.href = 'javascript:void(0);'
            }
        })
        
        headerPrimary.querySelectorAll('#kt_header_nav_tabs a.nav-link').forEach(el => el.classList.remove('active'))
        headerSecondary.querySelectorAll('[role="tabpanel"]:has(a.menu-link.active:not(.skip-highlight))').forEach((el) => {
            el.classList.add('active', 'show')
            headerPrimary.querySelector('#kt_header_nav_tabs a.nav-link[href="#' + el.id + '"]').classList.add('active')
        })
        headerSecondary.querySelectorAll('.menu-accordion:has(a.menu-link.active)').forEach((el) => el.classList.add('hover', 'show'))
    }

    return {
        init: function () {
            if (header) init()
        }
    }
}

KTUtil.onDOMContentLoaded(function () {
    // MetronicLandingHeader().init();
});