"user strict";

const MetronicAside = function () {
    const aside = document.querySelector('#kt_aside')
    let asidePrimary
    let asideSecondary
    let toggleElement

    const init = function () {
        toggleAside()
        initAside()
        selectEnviroment()
    }

    initAside = () => {
        asidePrimary = aside.querySelector('.aside-primary')
        asideSecondary = aside.querySelector('.aside-secondary')
        
        asideSecondary.querySelectorAll('a.menu-link:not(.skip-highlight):not(.force-highlight)').forEach(el => el.classList.remove('active'))
        asideSecondary.querySelectorAll('[role="tabpanel"]').forEach(el => el.classList.remove('active', 'show'))
        asideSecondary.querySelectorAll('.menu-accordion').forEach(el => el.classList.remove('hover', 'show'))
        
        asideSecondary.querySelectorAll('a.menu-link.force-highlight').forEach(el => el.classList.add('active'))
        asideSecondary.querySelectorAll('a.menu-link:not(.skip-highlight)').forEach((el) => {
            if (el.href.substring(el.href.lastIndexOf(_.DIR)) === window.location.href) {
                el.classList.add('active')
                el.href = 'javascript:void(0);'
            }
        })
        
        asidePrimary.querySelectorAll('#kt_aside_nav_tabs a.nav-link').forEach(el => el.classList.remove('active'))
        asideSecondary.querySelectorAll('[role="tabpanel"]:has(a.menu-link.active:not(.skip-highlight))').forEach((el) => {
            el.classList.add('active', 'show')
            asidePrimary.querySelector('#kt_aside_nav_tabs a.nav-link[href="#' + el.id + '"]').classList.add('active')
        })
        asideSecondary.querySelectorAll('.menu-accordion:has(a.menu-link.active)').forEach((el) => el.classList.add('hover', 'show'))
        asideSecondary.querySelectorAll('.menu-accordion.force-highlight').forEach((el) => el.classList.add('hover', 'show'))
        asideSecondary.querySelectorAll('[role="tabpanel"]:has(.menu-accordion.force-highlight)').forEach((el) => {
            el.classList.add('active', 'show')
            asidePrimary.querySelector('#kt_aside_nav_tabs a.nav-link[href="#' + el.id + '"]').classList.add('active')
        })
        asideSecondary.querySelectorAll('.menu-accordion:has(.menu-accordion.force-highlight)').forEach((el) => el.classList.add('hover', 'show'))
    }

    const toggleAside = () => {
        toggleElement = document.querySelector('#kt_aside_toggle')
        if (!toggleElement) return
        const toggle = KTToggle.getInstance(toggleElement)
        const state = KTCookie.get("sidebar_minimize_state")
        state === 'off' ? toggle.disable() : toggle.enable()

        toggle.on("kt.toggle.changed", function () {
            const date = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) // 30 days from now
            KTCookie.set("sidebar_minimize_state", toggle.isEnabled() ? "on" : "off", { expires: date })
        })
    }

    const selectEnviroment = () => {
        const buttons = document.querySelectorAll('[data-cpanel-select]')
        buttons.forEach(button => button.addEventListener('click', async () => {
            const CPANEL_LAYOUT = button.dataset.cpanelSelect
            console.log(CPANEL_LAYOUT)
            const response = await AJAX_DATA({
                method: 'POST',
                url: _.AJAX + 'ajax.site.php',
                data: {
                    PHP_SESSION: true,
                    var_name: 'CPANEL_LAYOUT',
                    value: CPANEL_LAYOUT
                }
            })
            // zApp.reloadPage()
            window.location.href = ROOT_DIR
        }))
    }

    return {
        init: function () {
            if (aside) init()
        }
    }
}

KTUtil.onDOMContentLoaded(function () {
    MetronicAside().init();
});